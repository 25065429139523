import React from 'react'
import Button from "@button";

const AddToBagButton = (props) => {
  const {onClick, disabled, changeLabelOnHover, inverted, clickedWithoutSizeSelected, isShort, isPreorder} = props;
  return <Button
    {...props}
    sx={({disabled}) => (
      {
        color: disabled ? 'mono500' :  [
          'white',
          null,
          null,
          'black'
        ],
        border: [
          null,
          null,
          null,
          disabled ? '1px solid mono700' : '1px solid currentColor'
        ],
        height: [
          '40px',
          null,
          null,
          '35px'
        ],
        width: '100%',
        textAlign: 'center',
        font: 'body02',
        transition: '300ms',
        bg: disabled ? 'mono700' : ['black',null,null,'transparent'],
        'span' : {
          display: [
            isShort? 'none' : 'inline',
            null,
            null,
            'inline'
          ]
        }
      })
    }
  >
    {
      ({disabled, hovered}) => disabled ?
        'Adding...'
        :
        clickedWithoutSizeSelected || (hovered && changeLabelOnHover) ? 'Select a size' : <>
          {
            isPreorder ?
              'Preorder'
              :
              <>Add <span> to bag</span></>
          }

        </>
    }
  </Button>
};

export default AddToBagButton