import React from 'react'
import Button from "@button";
import Image from "@image";
import Link from "@link";
import IconClose from "../icons/IconClose";
import ButtonPrimary from "../buttons/ButtonPrimary";
import useCart from "@data/useCart";
import styledBox from "../../styledBox";

const Root = styledBox({
  bg: 'white',
  boxShadow: '0px 0px 14px rgba(0,0,0,0.05)',
  minWidth: '300px',
  display: 'grid',
  px: [
    'containerMargin',
    'containerMargin',
    0,
    0,
    0,
    0,
  ],
  gridTemplateColumns: [
    '90px 1fr',
    '90px 1fr',
    '90px 1fr',
    '90px 1fr',
    '90px 1fr'
  ],
  py: [
    's7',
    's7',
    0,
    0,
    0
  ]
})
const Content = styledBox({display: 'flex', justifyContent: 'space-between', p: ['none','none','s7'] ,pl: 's7' })
const TextBox = styledBox({display: 'flex', flexDirection: 'column', justifyContent: 'space-between', font: 'body02' })

const TitleBox = styledBox({color: 'mono500'})
const CheckoutBoxDesktop = styledBox({ display: ["none", "none", 'block'] })
const CheckoutBox = styledBox({
  gridColumn: 'span 2', display: ["grid", "grid", 'none'], pt: 's7' }
  )

const CheckoutLink = Link.styled({
  sx: { font: 'body02', textDecoration: 'underline' }
})


const BuyNotification = ({onClose, lineItem}) => {
  const cart = useCart();

  const url = cart ? cart.checkoutUrl : "#";

  return <Root>
    <div>
      <Image
        image={lineItem.product.primaryImage}
        sizes={"90px"}
      />
    </div>

    <Content>
      <TextBox>
        <div>
          <div>Added to bag</div>
          <TitleBox>{lineItem.product.title}</TitleBox>
        </div>

        <CheckoutBoxDesktop>
          <CheckoutLink  href={url} external={true} >Checkout</CheckoutLink>
        </CheckoutBoxDesktop>
      </TextBox>
      <div>
        <Button onClick={onClose} sx={{p: 8, mt: -4}}><IconClose size={20}/></Button>
      </div>
    </Content>

    <CheckoutBox>
      <ButtonPrimary href={url} external={true}>Checkout</ButtonPrimary>
    </CheckoutBox>

  </Root>
};


export default BuyNotification
