import { useState, useContext, useEffect } from "react";
import { StoreContext } from "./StoreProvider";

function uuidv4() {	
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {	
      var r = (Math.random() * 16) | 0,	
        v = c === 'x' ? r : (r & 0x3) | 0x8;	
      return v.toString(16);	
    });	
  }

function useCartModifier(props) {
    const context = useContext(StoreContext);
    const [cartModifierId, setCartModifierId] = useState(null)

    const isProcessing = context.cartModifierProcessing[cartModifierId] 
    
    useEffect(() => {
        if(props?.products) {
            context.setProducts(props.products)
        }
        const uuid = uuidv4()
        setCartModifierId(uuid)
    }, [])

    if (typeof window === 'undefined') {
        return undefined;
    }

    return {
        add: (...args) => {
            if (isProcessing) {
                return;
            }
            return context.add(...args, 1, cartModifierId)
        },
        remove: (...args) => {
            if (isProcessing) {
                return;
            }
            return context.remove(...args, cartModifierId)
        },
        replace: (...args) => {
            if (isProcessing) {
                return;
            }
            return context.replace(...args, cartModifierId)
        },
        // replace,
        isProcessing
    };
}

export default useCartModifier;
