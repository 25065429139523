import React from 'react'
import Button from "@button";
import IconClose from "../icons/IconClose";
import styledBox from "../../styledBox";

const Root = styledBox({
  bg: 'white',
  boxShadow: '0px 0px 14px rgba(0,0,0,0.05)',
  minWidth: '300px',
  px: [
    'containerMargin',
    'containerMargin',
    0,
    0,
    0,
    0,
  ],
  py: [
    's8',
    's8',
    0,
    0,
    0
  ]
})
const Content = styledBox({display: 'flex', justifyContent: 'space-between', p: ['none','none','s7'] ,pl: 's7' })
const TextBox = styledBox({display: 'flex', flexDirection: 'column', justifyContent: 'space-between', font: 'body02' })


const AlertNotification = ({onClose, message}) => {

  return <Root>

    <Content>

      <TextBox>
          {message}
      </TextBox>
      <div>
        <Button onClick={onClose}><IconClose size={18}/></Button>
      </div>
    </Content>

  </Root>
};


export default AlertNotification
