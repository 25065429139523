import React, {useState, useEffect, useRef} from 'react'
import styledBox from "../../styledBox";

const InputStyled = styledBox("input", {

  height: 35,
  font: 'input01',
  bg: 'transparent',
  borderBottom: '1px solid',
  borderColor: 'mono500',
  borderRadius: 0,
  "::placeholder" : {
    color: 'mono600'
  }

})

const Input = React.forwardRef((props, ref) => {
  return <InputStyled
      ref={ref}
      type={props.type ? props.type : 'text'}
      {...props}
    />
})

export default Input