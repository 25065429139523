const moveGlobalCursor = () => {
  let elem = document.getElementById('global-cursor');
  if(!elem) {return}
  elem.style.transform = `translate( ${event.clientX}px, ${event.clientY}px)`
  if (elem.style.opacity == 0) {
      showGlobalCursor()
    }
}

const showGlobalCursor = label => {
  let elem = document.getElementById('global-cursor');
  if(!elem) {return}
  if(label) {
    elem.innerText = label
  }
  if (elem.style.opacity == 1) {
    return
  }
  document.getElementById('global-cursor').style.opacity = 1

}
const hideGlobalCursor = () => {
  let elem = document.getElementById('global-cursor');
  if(!elem) {return}
  if(elem.style.opacity == 1) {
    elem.style.opacity = 0
  }

}

export {
  showGlobalCursor,
  hideGlobalCursor,
  moveGlobalCursor,
}