import React from 'react';
import styledBox from "../../styledBox"

const r = 50;
const thickness = 2;
const track = `${thickness}px solid rgba(200,200,200,0.2)`;
const ride = `${thickness}px solid rgba(200,200,200)`;

const Spinner = styledBox({
  width: props => props.r ?? '100%',
  height: props => props.r ?? '100%',
  borderRadius: '50%',
  position: 'relative',
  borderTop: track,
  borderRight: track,
  borderBottom: track,
  borderLeft: ride,
  transform: 'translateZ(0)',
  animation: 'load8 1.1s infinite linear',
  'div': {
    width: props => props.r ?? '100%',
    height: props => props.r ?? '100%',
    borderRadius: '50%',
  },
  '@keyframes load8' : {
    '0%' : {
      transform: 'rotate(0deg)'
    },
    '100%' : {
      transform: 'rotate(360deg)'
    }
  }
});
const Root = styledBox({
  height: '100vh',
  width: '100%',
  position: 'relative',
});
const AdaptiveLoaderRoot = styledBox({
  width: '100%',
  height: '100%',
  // bg: 'cream',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const FitLoader = ({r}) => {

  return <AdaptiveLoaderRoot>
    <Spinner className={"spinner"} r={r}>
      <div/>
    </Spinner>
  </AdaptiveLoaderRoot>

}

const PageLoader = ({}) => {
  return <Root>
    <FitLoader r={50}/>
  </Root>
};

export default PageLoader

export {FitLoader}