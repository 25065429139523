export const GA_TRACKING_ID = 'UA-54083143-1';
export const FACEBOOK_PIXEL_ID = "588789945107760";

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const event = ({ action, category, label, value }) => {
//     window.gtag('event', action, {
//         event_category: category,
//         event_label: label,
//         value: value,
//     })
// }

function getGAProductFromProduct(product) {
    return {
        id: product.handle,
        name: product.title
    }
}

function getGAProductFromVariant(variant, quantity) {
    return {
        id: variant.product.handle,
        name: variant.product.title,
        variant: variant.title,
        quantity,
        price: variant.priceV2.amount,
    }
}

const Analytics = {

    pageview: (url) => {

        // FB
        if (typeof fbq !== 'undefined') {
            fbq('track', 'PageView');
        }

        // GA
        // if (window.gtag) {
        //     window.gtag('config', GA_TRACKING_ID, {
        //         page_path: url,
        //     })
        // }

        window.dataLayer.push({
            'event':'virtualPageView',
            'path': url
        });

        // segment
        // if (typeof analytics !== 'undefined') {
        //     analytics.page();
        // }
    },

    search: (query) => {

        // segment
        // if (typeof analytics !== 'undefined') {
        //     analytics.track('Products Searched', {
        //         query: query
        //     });
        // }

        if (typeof fbq !== 'undefined') {
            fbq('track', 'Search');
        }

        if (window.gtag) {
            window.gtag('event', 'search', {
                search_term: query
            })
        }
    },

    productDetailsView: (product) => {

        // GA
        if (window.gtag) {
            window.gtag('event', 'view_item', {
                // event_category: 'engagement',
                event_label: `${product.title}`,
                items: [getGAProductFromProduct(product)]
            })
        }



    },

    addToBag: (variant, quantity, list_name) => {

        // GA
        if (window.gtag) {
            // console.log('add to bag', getGAProductFromVariant(variant, quantity));
            window.gtag('event', 'add_to_cart', {
                // event_category: 'EnhancedEcommerce',
                event_label: `${variant.product.title} - ${variant.title}`,
                currency: variant.priceV2.currencyCode,
                list_name,
                items: [getGAProductFromVariant(variant, quantity)]
            })
        }

        if (window.ga) {

        }

        if (typeof fbq !== 'undefined') {
            fbq('track', 'AddToCart');
        }

        window.dataLayer.push({
            'event':'mp_addToCart',
            'items_names': `${variant.product.title} - ${variant.title}`,
            'items_ids': `${variant.sku}`,
            'items_quantity': quantity,
            'items_prices': `${variant.priceV2.amount}`
        })


    },

    removeFromBag: (variant, quantity) => {

        // GA
        if (window.gtag) {
            window.gtag('event', 'remove_from_cart', {
                // event_category: 'EnhancedEcommerce',
                event_label: `${variant.product.title} - ${variant.title}`,
                currency: variant.priceV2.currencyCode,
                items: [getGAProductFromVariant(variant, quantity)]
            })
        }
    },

    newsletterSignup: (email)  => {
        if (window.gtag) {
            window.gtag('event', 'newsletter_signup', {
                event_category: 'engagement',
                // event_label: 'Newsletter signup',
                value: email
                });
        }
    }

};

export default Analytics;
