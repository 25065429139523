import Button from "@button";
import React from "react";


const BUTTON_HEIGHT = 35;


// const ButtonPrimary = (props) => {
//   return <Button
//     {...props}
//     sx={
//       ({hovered, disabled}) => ({
//         height: BUTTON_HEIGHT,
//         bg: disabled ? 'mono500' : 'black',
//         color: 'white',
//         whiteSpace: 'nowrap',
//         font: 'heading01',
//         lineHeight: '33px',
//         textAlign: 'center',
//       })
//     }
//   />
// };

const ButtonPrimary = Button.styled({
  sx: {
    height: BUTTON_HEIGHT,
    bg: (p, s) => s.disabled ? 'mono500' : 'black',
    color: 'white',
    whiteSpace: 'nowrap',
    font: 'heading01',
    lineHeight: '33px',
    textAlign: 'center',
  }
});


export default ButtonPrimary
