import React from 'react'
import Selectable from "@commerce-ui/core2/Selectable";
import styledBox from "../../styledBox";

const Root = styledBox({
  position: 'relative',
  width: "100%",
  height: props => [
    "54px",
    "54px",
    "54px",
    props.shortOnDesktop? "24px" : "50px",
  ],
  display: "flex",
  padding: "0 12px",
  px: 's8',
  alignItems: "center",
  justifyContent: 'center',
  font: "heading01",
  lineHeight: 1,
  border: props => props.invalid && props.selected ? "1px solid red" : "none",
  bg: props => [
    props.highlighted ? "cream500" : "cream",
    props.highlighted ? "cream500" : "cream",
    props.highlighted ? "cream500" : "white",
  ],
  color: props => props.outOfStock ? "mono500" : "black",
  "&:hover": {
    bg: 'cream500'
  },
});

const LowStockLabel = styledBox({
  font: 'heading09',
  color: 'red',
  display: props => props.hideOnDesktop ? 'none' : null
});

const  JoinTheWaitlist = styledBox({
  font: 'heading01',
  textDecoration: 'underline',
  display: props => props.hideOnDesktop ? 'none' : null
})

const ExtraLabel = styledBox({
  display: 'flex',
  position: 'absolute',
  top:  0, right: 0,
  width: '50%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'flex-end',
  pr: 's8'
})
const MainLabel = styledBox({
})

function SelectableRow(props) {
  return (
    <Selectable {...props}>
      {
        ({ disabled, highlighted, selected, label, invalid, isLowStock, option, shortOnDesktop }) => {
          return (
            <Root
              shortOnDesktop={shortOnDesktop}
              highlighted={highlighted}
              selected={selected}
              invalid={invalid}
              outOfStock={option.quantityAvailable === 0}
            >
              <MainLabel>
                {label}
              </MainLabel>
              <ExtraLabel>
                {
                  option.quantityAvailable === 1 &&
                  <LowStockLabel hideOnDesktop={shortOnDesktop}>
                    Only 1 item left
                  </LowStockLabel>
                }
                {
                  option.quantityAvailable === 0 &&
                  <JoinTheWaitlist hideOnDesktop={shortOnDesktop}>Notify me</JoinTheWaitlist>
                }
              </ExtraLabel>
            </Root>
          )
        }
      }
    </Selectable>
  );
}

export default SelectableRow